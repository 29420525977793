import { datadogRum } from '@datadog/browser-rum'
import axios from 'axios'
import type {
  ChargeType,
  Delivery,
  OnlineOperationalMenu,
  ServerVenue,
} from '@/libs/helpers/adapters'
import {
  CartItem,
  ConsumerAddress,
  OrderItem,
  OrderMethod,
  OrderProvider,
  mapItemsToCartRequest,
} from '@/libs/helpers/utils'
import type { OrderingAddress } from '@/providers/googleMaps'
import type { SearchResults } from '@/providers/state/search'

const consumerFrontendBaseURL =
  typeof window === 'undefined'
    ? `${process.env.VENUE_CONFIG_SERVICE_BASE_URL}/consumer-frontend`
    : '/api/consumer-frontend'

const consumerFrontendClient = axios.create({
  baseURL: consumerFrontendBaseURL,
})

const orderingClient = axios.create({
  baseURL: `${consumerFrontendBaseURL}/ordering`,
})

export interface VenueEntities {
  venue: ServerVenue
  loyaltyConfig: VenueLoyalty
  paymentMethods: PaymentMethod[]
  tbPaymentType: TBPaymentType | null
  advancedDiscountsConfig: {
    billProcessorEnabled: boolean
  }
}

export enum ScheduleUnavailableReason {
  ORDERS_LIMIT_REACHED = 'ORDERS_LIMIT_REACHED',
  VENUE_CLOSURE = 'VENUE_CLOSURE',
}

export interface ScheduleTimeslotWithReason {
  time: string
  available: boolean
  unavailabilityReason: ScheduleUnavailableReason | null
}

export interface Schedule {
  schedulingTimeslots: ScheduleTimeslotWithReason[]
}

export interface Branding {
  logoImage: {
    image: {
      url: string
      caption: string | null
      imageXRefID: string
      originalName: string | null
    } | null
    inheritedFromBaseXRefID: string
  }
  bannerImage: {
    image: {
      url: string
      caption: string | null
      imageXRefID: string
      originalName: string | null
    } | null
    inheritedFromBaseXRefID: string
  }
  primaryColor: { hex: string; inheritedFromBaseXRefID: string }
  secondaryColor: { hex: string; inheritedFromBaseXRefID: string }
}

interface AppliedGiftCard {
  amountSpent: string
  remainingBalance: string
}

export enum ServiceChargeType {
  DELIVERY_FEE = 'DELIVERY_FEE',
  MANDATORY_GRATUITY = 'MANDATORY_GRATUITY',
}

export interface BillServiceCharge {
  label: string
  serviceChargeXRefID: string
  type: ServiceChargeType
  chargeType: ChargeType
  isPreDiscount: boolean
  chargeAmount: string
  taxAmount: string
  totalAmount: string
  isWaived: boolean
  taxes: { label: string; amount: string }[]
}

export interface Bill {
  subtotal: string
  totalAfterTax: string
  totalTax: string
  grandTotal: string
  isEstimated: boolean
  isInclusive: boolean
  tipTotal?: string
  discountAmount?: string
  giftCardPayments?: AppliedGiftCard[]
  outstandingAmount?: string
  serviceCharges: BillServiceCharge[]
}

export interface Discount {
  discountId: string
  label: string
  amountType: 'PERCENTAGE' | 'DOLLAR'
  discountType: 'ITEM' | 'ORDER' | 'BOGO'
  applyType: 'AUTOMATIC' | 'MANUAL' | 'PROMO'
}

export interface DiscountDetails {
  orderItemDiscounts: Record<string, OrderItemDiscount[]>
  billTotalDiscounts: BillTotalDiscount[]
  promoCodes: string[]
  promoCodeErrors: string[]
}

export interface OrderItemDiscount {
  discountId: string
  label: string
  total: string
  applyType: 'AUTOMATIC' | 'MANUAL' | 'PROMO'
}

export interface BillTotalDiscount {
  discountId: string
  label: string
  total: string
  applyType: 'AUTOMATIC' | 'MANUAL' | 'PROMO'
}

export interface Cart {
  orderMethod: OrderMethod | undefined
  items: CartItem[]
  orderingAddress?: OrderingAddress
  scheduledFor?: string
}

export interface CartUpdateRequest extends Cart {
  tipAmount?: string
  selectedRewardXRefID?: string
}

export interface CartResponse extends Cart {
  items: OrderItem[]
  bill: Bill
  loyaltyRewardApplied?: LoyaltyReward
  delivery?: Delivery
  discountedOrderItemID?: string
  discountDetails?: DiscountDetails
}

export interface Me {
  cart: CartResponse
  giftCards: GiftCard[]
  deliveryAddresses: ConsumerAddress[]
  loyaltySession: LoyaltySession
  loyalty?: Loyalty
  guest?: GuestInformation | null
  transactionDetails?: TransactionSetupResponse
  orderRequest?: OrderRequest
  marketingConsent?: MarketingConsent
}

export interface UpdateMeRequest {
  cart: CartUpdateRequest
  selectedRewardXRefID?: string | null
  giftCardCode?: string | null
  code?: string
  recaptchaToken?: string
  guest?: GuestInformation
  transactionDetails?: TransactionSetupResponse
  orderRequest?: OrderRequest
  promoCodes?: string[]
}

export interface GiftCard {
  code: string
  referenceId?: string
  currentValue: string
  message?: string
}

export interface GiftCardRequest {
  code: string
  recaptchaToken: string
}
export interface EvaluateLoyaltyRewards {
  loyalty: Loyalty | null
  selectedRewardXRefID?: string | null
}

export interface LoyaltyTransactionEarning {
  currency: string
  debitAmount: string
  creditAmount: string
  data?: LoyaltyReward
}

export interface OrderLoyalty {
  loyaltyTransactionXRefID: string
  redemptions: LoyaltyTransactionEarning[]
  earnings: LoyaltyTransactionEarning[]
  pointsBalance: string
}

export enum CardCountry {
  US = 'US',
  CA = 'CA',
}

export interface OrderPayment {
  paymentMethod: PaymentMethod
  paymentCardToken?: string
  country?: CardCountry
  postalCode?: string
  tipAmount?: string
  currency?: string
}

interface OrderRecipientInformation {
  firstName: string
  lastName: string
  email: string
}

interface PaymentProviderResponse {
  transactionID: string
  avsResponseCode: string
  cvvResponseCode: string
  approvedAmount: string
  lastFour: string
  cardLogo: string
  expressResponseMessage: string
  expressResponseCode: string
  approvalCode: string
  transactionDate: string
}

export interface OrderRequest {
  paymentMethod: PaymentMethod
  orderMethod: OrderMethod
  providerID: string
  items: CartItem[]
  locale: string
  phone: string
  isScheduled?: boolean
  scheduledFor?: string
  orderingAddress?: Omit<OrderingAddress, 'formattedAddress'>
  paymentCardToken?: string
  smsNotification?: boolean
  postalCode?: string
  tipAmount?: string
  country?: string
  notes?: string
  loyaltyTransactionXRefID?: string
  selectedRewardXRefID?: string
  loyaltyDiscountTotal?: string
  selectedGiftCardCode?: string | null
  recipientInformation?: OrderRecipientInformation
  paymentDetails?: {
    paymentType: TBPaymentType
    paymentProviderResponse: PaymentProviderResponse
  }
  validationCode?: string | null
  marketingEmailConsent?: boolean
}

export interface Order {
  items: OrderItem[]
  orderMethod: OrderMethod
  orderXRefID: string
  venueXRefID: string
  orderNumber: string
  isScheduled: boolean
  requestedAt: string
  locale: string
  phone: string
  waitTime: number
  bill: Bill
  deliveryAddress?: OrderingAddress
  payment: OrderPayment
  completedAt?: string
  acceptedAt?: string
  declinedAt?: string
  scheduledFor?: string
  notes: string
  loyalty?: OrderLoyalty
  providerXRefID: OrderProvider
  discountDetails?: DiscountDetails
}

export enum PaymentMethod {
  CASH = 'CASH',
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  ONLINE = 'ONLINE',
}

export enum TBPaymentType {
  WEPAY = 'WEPAY',
  PAYRIX = 'PAYRIX',
  WORLDPAY = 'WORLDPAY',
}

export interface VenueLoyalty {
  config?: {
    tableUpRestaurantId: number
    tbPaymentsRestaurantId?: number
    tableUpGroupId: number
    giftCardsEnabled: boolean
    loyaltyEnabled: boolean
    marketingEnabled: boolean
    rewards: LoyaltyReward[]
    loyaltyPackage: string
  }
  error?: {
    code: string
    message?: string
  }
}

export interface LoyaltySession {
  loyaltyTransactionXRefID?: string | null
  selectedRewardXRefID?: string | null
  selectedGiftCardCode?: string | null
}

export enum TransactionStateInResponse {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  REVOKED = 'REVOKED',
  ACCEPTED = 'ACCEPTED',
  FAILED = 'FAILED',
}

interface LinkedAccounts {
  userID?: string
  hash?: string
  type: 'email' | 'phone' | 'app' | 'giftcard'
  phone?: string
  email?: string
}

interface LoyaltyUserInfo {
  phone: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  source: 'Lookup' | 'QRDynamic' | 'QRStatic'
  type: 'email' | 'phone' | 'app' | 'giftcard' | ''
  linkedAccounts: LinkedAccounts[] | null
}

interface Balance {
  currency?: string
  currentValue?: string
  appliedValue?: string
  earnedValue?: string
  label: string
}

interface ItemLabel {
  id: string
  label: string
  xRefID: string
}

export enum RewardType {
  ORDER = 'ORDER',
  ITEM = 'ITEM',
}

export interface LoyaltyReward {
  rewardXRefID: string
  name: string
  description: string | null
  points: string
  menuItem?: ItemLabel
  rewardType?: RewardType
  discountAmount?: string
}

interface AppliedReward {
  rewardXRefID: string
  name: string
  discountAmount: string
  points: string
  upc: string
  menuItem?: ItemLabel
  rewardType?: RewardType
  redemptionXRefID: string
}

export interface Loyalty {
  state: TransactionStateInResponse
  transactionXRefID: string
  venueXRefID: string
  providerXRefID: string
  user: LoyaltyUserInfo
  balances: Balance[]
  rewards: LoyaltyReward[]
  appliedRewards: AppliedReward[]
  availableActions: {
    type: string
    data: {
      rewards: {
        rewardXRefID: string
      }[]
    }
  }[]
  creditedAccounts: {
    amount: string
    itemID?: string
  }[]
}

export interface ConsumerProfile {
  consumerXRefID: string
  firstName: string
  lastName: string
  email: string
  phone?: string
}

export interface GuestInformation {
  firstName: string
  lastName: string
  email: string
  phone: string
  smsConsent: boolean
}

export interface CheckUserRequest {
  phone?: string
  email?: string
}

export interface CheckUserResponse {
  tableupAccountExists: boolean
  tbdineAccountExists: boolean
}

export interface AuthMe {
  user: ConsumerProfile | null
}

export interface MarketingConsent {
  emailConsent: boolean
}

export async function getAuthMe(options?: { headers?: Record<string, string> }): Promise<AuthMe> {
  const { data } = await consumerFrontendClient.get<AuthMe>('/auth/me', options)
  return data
}

export async function getVenue(
  venueXRefID: string,
  options: {
    headers?: Record<string, string>
    params: {
      orderMethod: OrderMethod
      isScheduled?: boolean
      providerXRefID?: OrderProvider
    }
  }
): Promise<VenueEntities> {
  const { data: venueEntities } = await orderingClient.get<VenueEntities>(
    `/v2/venues/${venueXRefID}`,
    options
  )

  return venueEntities
}

export async function getSchedule(
  venueXRefID: string,
  body: {
    orderingAddress?: OrderingAddress
    orderMethod: OrderMethod
    orderDate: string
  }
): Promise<Schedule> {
  const { data: schedule } = await orderingClient.post<Schedule>(
    `/v1/venues/${venueXRefID}/schedule`,
    body
  )

  return schedule
}

export async function getBranding(
  venueXRefID: string,
  options: {
    headers?: Record<string, string>
  }
): Promise<Branding> {
  const { data: branding } = await orderingClient.get<Branding>(
    `/v1/venues/${venueXRefID}/branding`,
    options
  )

  return branding
}

export async function getOnlineOperationalMenu(
  venueXRefID: string,
  options: {
    headers?: Record<string, string>
    params: {
      orderMethod: OrderMethod
      orderTime?: string
    }
  }
): Promise<OnlineOperationalMenu> {
  const { data: menu } = await orderingClient.get<OnlineOperationalMenu>(
    `/v2/venues/${venueXRefID}/menu`,
    options
  )

  return menu
}

export async function getMe(
  venueXRefID: string,
  options: {
    headers: Record<string, string>
    params?: { ignoreTip?: boolean }
  }
): Promise<Me> {
  const { data: me } = await orderingClient.get<Me>(`/v1/venues/${venueXRefID}/me`, options)

  return me
}

export async function getSearchVenue(options: {
  headers?: Record<string, string>
  params: {
    minDistanceKM?: number
    maxDistanceKM?: number
    orderMethod: OrderMethod
    query?: string
    consumerLongitude?: number
    consumerLatitude?: number
    limit?: number
  }
}): Promise<SearchResults> {
  const { data: results } = await orderingClient.get<SearchResults>('/v1/venues/search', options)

  return results
}

export async function getOrder(
  orderXRefID: string,
  options?: {
    headers?: Record<string, string>
  }
): Promise<Order> {
  const { data: order } = await orderingClient.get<Order>(`/v1/orders/${orderXRefID}`, options)

  return order
}

export async function updateMe(venueXRefID: string, meRequest: UpdateMeRequest): Promise<Me> {
  const { data: me } = await orderingClient.put<Me>(`/v1/venues/${venueXRefID}/me`, meRequest)

  return me
}

// Order
export async function requestOrder(
  venueXRefID: string,
  orderRequest: OrderRequest,
  options?: {
    headers?: Record<string, string>
  }
): Promise<Order> {
  const { data: order } = await orderingClient.post<Order>(
    `/v1/venues/${venueXRefID}/orders`,
    orderRequest,
    options
  )

  return order
}

// Loyalty Rewards
export async function evaluateRewards(
  venueXRefID: string,
  consumerXRefID: string,
  evalRewardsRequest: Cart
): Promise<EvaluateLoyaltyRewards> {
  const { data: userRewards } = await orderingClient.post<EvaluateLoyaltyRewards>(
    `/loyalty/v1/venues/${venueXRefID}/consumers/${consumerXRefID}/actions/evaluateLoyaltyRewards`,
    {
      cart: {
        ...evalRewardsRequest,
        items: (evalRewardsRequest.items ?? []).map(mapItemsToCartRequest),
      },
    }
  )

  return userRewards
}

export async function checkUser(checkUserRequest: CheckUserRequest): Promise<CheckUserResponse> {
  const { data: checkUserResponse } = await orderingClient.post<CheckUserResponse>(
    `/v1/users/check-user`,
    checkUserRequest
  )
  return checkUserResponse
}

export type TransactionSetupResponse = {
  transactionSetupID: string
}

export type TransactionSetupRequestAddress = {
  address1: string
  address2?: string
  city: string
  state: string
  zipcode: string
}

export type TransactionSetupRequest = {
  amount: string
  logoURL?: string
  returnURL: string
  companyName?: string
  address: TransactionSetupRequestAddress
  tagline?: string
  welcomeMessage?: string
  processTransactionTitle?: string
  duplicateCheckDisableFlag?: boolean
  customCss?: string
  orderDetails?: string
}

export type StreetAddressState = Omit<TransactionSetupRequestAddress, 'zipcode'> & {
  formattedAddress: string
  country: string
}

export async function transactionSetup(
  venueXRefID: string,
  transactionSetupRequest: TransactionSetupRequest
): Promise<TransactionSetupResponse> {
  const { data } = await orderingClient.post(
    `/v1/venues/${venueXRefID}/transaction-setup`,
    transactionSetupRequest
  )
  return data
}

export type TransactionRefundRequest = {
  amount: string
  tbPaymentType: string
  transactionID: string
}

export enum RefundDeclineReason {
  PAYMENT_NOT_COMPLETED = 'One or more payment(s) is incomplete. Please check payment status(es)',
  PAYMENT_TOO_OLD = 'Payment is over 60 days old and cannot be refunded',
  REFUND_FEE_MUST_BE_FULL_AMOUNT = 'Refund amount must match original payment amount. Please verify all fees and taxes are included in the refund',
  REFUND_EXCEEDS_BALANCE = 'Refund amount currency exceeds original payment amount. Please verify total refund amount',
  REFUND_EXCEEDS_ALLOWED_LIMIT = 'Refund amount exceeds allowed amount limit',
  REFUND_FEE_EXCEEDS_FEE_BALANCE = 'Refund fee must not be greater than the non-refunded fee balance',
  REFUND_ALREADY_COMPLETED = 'Refund is already processed for this payment',
}

export type TransactionRefundResponse = {
  refundTransactionID: string
  providerXRefID: string
  gatewayXRefID: string
  transactionType: string
  authorizationState: string
  settlementState: string
  providerTransactionID: string
  amount: {
    amount: string
    currency: string
  }
  declineReason?: keyof typeof RefundDeclineReason
}

export async function transactionRefund(
  venueXRefID: string,
  transactionRefundRequest: TransactionRefundRequest,
  options?: {
    headers?: Record<string, string>
  }
): Promise<TransactionRefundResponse> {
  const { data } = await orderingClient.post(
    `/v1/venues/${venueXRefID}/transaction-refund`,
    transactionRefundRequest,
    options
  )
  return data
}

export enum PayrixCountryEnum {
  USA = 'USA',
  CAN = 'CAN',
}

export type PayrixSessionResponse = {
  country: PayrixCountryEnum
  sessionKey: string
  merchantXRefID: string
}

export async function createPayrixSession(venueXRefID: string): Promise<PayrixSessionResponse> {
  let wafToken
  try {
    wafToken = await window.AwsWafIntegration?.getToken()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('unable to acquire waf token, will still attempt request', { err })
    datadogRum.addError(err)
  }

  let config
  if (wafToken != null) {
    config = {
      headers: {
        'x-aws-waf-token': wafToken,
      },
    }
  }

  const { data } = await orderingClient.post(
    `/v1/venues/${venueXRefID}/create-payrix-session`,
    null,
    config
  )
  return data
}
