import {
  ModalPageErrorCode,
  StaleCartErrorCode,
  LoyaltyRewardErrorCode,
  OrderSubmitErrorCode,
  ScheduledOrderErrorCode,
} from './errorMapper'
import { parseRequestError, ParsedError } from './error_handler'

interface PostErrorActions {
  onLoyaltyError?: () => void
  onStaleError?: () => void
  onOrderingAddressError?: () => void
  onThrottlingLimitReached?: () => void
  onOutsideTimeslots?: () => void
  onDefaultFallback?: () => void
  onAdvancedDiscountError?: () => void
}

/* helper that allows the application to recover from expected cart errors
   and handle them accordingly */
function composeCartError(error: ParsedError | unknown, postErrorActions: PostErrorActions): void {
  const {
    onLoyaltyError,
    onStaleError,
    onOrderingAddressError,
    onDefaultFallback,
    onThrottlingLimitReached,
    onOutsideTimeslots,
  } = postErrorActions

  const { code } = parseRequestError(error)
  if (onLoyaltyError != null && code === LoyaltyRewardErrorCode.UnavailableReward) {
    onLoyaltyError()
    return
  }

  if (
    onOrderingAddressError != null &&
    (code === ModalPageErrorCode.DeliveryAddressOutOfBounds ||
      code === ModalPageErrorCode.InvalidAddress ||
      code === ModalPageErrorCode.NoVenueDeliveryBounds)
  ) {
    onOrderingAddressError()
    return
  }

  if (
    onStaleError != null &&
    (code === StaleCartErrorCode.MIN_MOD ||
      code === StaleCartErrorCode.MAX_MOD ||
      code === StaleCartErrorCode.MISSING_MODIFIER)
  ) {
    onStaleError()
    return
  }

  if (onOutsideTimeslots != null && code === ScheduledOrderErrorCode.VALIDATE_OUTSIDE_TIMESLOTS) {
    onOutsideTimeslots()
    return
  }

  if (
    onThrottlingLimitReached != null &&
    code === OrderSubmitErrorCode.ORDER_THROTTLING_LIMIT_ERROR
  ) {
    onThrottlingLimitReached()
    return
  }

  if (onDefaultFallback != null) {
    onDefaultFallback()
  }
}

export { composeCartError }
