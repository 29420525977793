import { Theme } from '@emotion/react'
import { AxiosError } from 'axios'
import { Big } from 'big.js'
import { FormatFunction, TFunction } from 'i18next'
import { DateTime, Interval } from 'luxon'
import { meetsContrastGuidelines } from 'polished'
import { formatTimeWithTimezone } from '@/libs/helpers/formatters'
import type {
  Delivery,
  OnlineOperationalMenuPage,
  OperationalMenu,
  Venue,
  VenueOrderingMethod,
} from './adapters'
import {
  type CartResponse,
  type ConsumerProfile,
  type GuestInformation,
  type PaymentMethod,
  type ScheduleTimeslotWithReason,
} from './apiClient'
import type { ServerErrorResponse } from './error_handler'

export enum OrderMethod {
  delivery = 'DELIVERY',
  pickup = 'PICKUP',
}

export enum OrderProvider {
  TOUCHBISTRO = 'TOUCHBISTRO',
  TOUCHBISTRO_DOORDASH = 'TOUCHBISTRO_DOORDASH',
}

export enum HeaderVariant {
  CHECKOUT = 'CHECKOUT',
  SEARCH = 'SEARCH',
  HOME = 'HOME',
  MENU = 'MENU',
}

export interface ParsedAxiosError {
  errorCode?: string
  message: string
  status: number
  statusText?: string
  type?: string
}

export type OptionsType = { label: string; value: string }

export interface BaseItem {
  quantity: string
  itemID: string
  note?: string
  price?: string
}

export interface CartChildItem extends BaseItem {
  modifierGroupXRefID: string
  // Used to identify which modifier within a particular modifier group is
  // being selected (`${modifierGroupXRefID}_${modifierXRefID}`)
  selectionId?: string
}

export interface CartItem extends BaseItem {
  id: string
  menuGroupXRefID: string
  childItems?: CartChildItem[]
}

export interface OrderChildItem extends BaseItem {
  price: string
  name: string
  modifierGroupXRefID: string
}

export interface OrderItem extends BaseItem {
  id: string
  price: string
  name: string
  menuGroupXRefID: string
  childItems?: OrderChildItem[]
}

export interface CartState extends CartResponse {
  isCartLoading: boolean
}

export interface ConsumerAddress {
  addressXRefID: string
  googlePlaceID: string
  formattedAddress: string
  aptAndSuiteNumber?: string
}

export enum DeliveryFleet {
  DOORDASH = 'DOORDASH',
  INVENUE = 'INVENUE',
}

export interface GTMSession {
  venueXRefID: string
  isTest: boolean
  guestCheckoutAvailable: boolean
  scheduledOrderingAvailable: boolean
  currency: string
  hasMenuItemImages: boolean
  menuItemImagesPct: number
  consumerXRefID: string | null
  isGuestCheckout: boolean
  isScheduledOrder: boolean
  orderThrottled: boolean
  fulfillmentType: OrderMethod | 'DOORDASH_DRIVE' | null
  deliveryFeeTotal: number
  orderSubtotal: number
  orderTotal: number
  orderItemsTotal: number
  discountTotal: number
  paymentType: PaymentMethod | null
}

export function dehydrateCartItems(items: OrderItem[]): CartItem[] {
  return items.map((item) => {
    return {
      id: item.id,
      itemID: item.itemID,
      quantity: item.quantity,
      note: item.note,
      childItems: item.childItems?.map((childItem) => ({
        itemID: childItem.itemID,
        quantity: childItem.quantity,
        note: childItem.note,
        modifierGroupXRefID: childItem.modifierGroupXRefID,
      })),
      menuGroupXRefID: item.menuGroupXRefID,
    }
  })
}

export function mapChildItemToCartRequest(childItem: CartChildItem): CartChildItem {
  return {
    quantity: Number(childItem.quantity).toFixed(2),
    itemID: childItem.itemID,
    note: childItem.note,
    modifierGroupXRefID: childItem.modifierGroupXRefID,
  }
}

export function mapItemsToCartRequest(item: OrderItem | CartItem): CartItem {
  return {
    id: item.id,
    childItems: item.childItems?.map(mapChildItemToCartRequest),
    quantity: Number(item.quantity).toFixed(2),
    itemID: item.itemID,
    note: item.note,
    menuGroupXRefID: item.menuGroupXRefID,
  }
}

export const parseHTMLEntities: FormatFunction = (string: string): string => {
  const conversions: Record<string, string> = {
    '&amp;': '&',
    '&quot;': '"',
  }
  return string && string.replace(/(&amp;|&quot;)/g, (match: string) => conversions[match])
}
export function parseErrorObject(axiosError?: AxiosError<ServerErrorResponse>): ParsedAxiosError {
  const { response } = axiosError || {}

  return {
    errorCode: response?.data?.error?.code ?? '',
    message: response?.data?.error?.message ?? '',
    status: response?.status ?? 500,
  }
}

export function trimAllWhitespace(value: string): string {
  return value != null ? value.replace(/\s+/g, '') : ''
}

/* helper used to calculate a total item cost
   the line item cost includes all modifiers
   i.e pizza for 9.99 with a total of 2.99 for all modifiers
   will show 12.98 on both the cart widget and order receipt 
*/
export function calcLineItemCost(item: OrderItem): string {
  let itemCost = item.price
  if (item.childItems) {
    let childItemCost = '0.00'
    item.childItems.forEach((childItem: OrderChildItem) => {
      childItemCost = Big(childItemCost).plus(childItem.price).toString()
    })

    itemCost = Big(itemCost).plus(childItemCost).toString()
  }

  const totalCost = Big(itemCost).times(item.quantity).toFixed(2)
  return totalCost
}

// helper to grab the order method settings for a given order method
export function getOrderMethodSettings(
  venueOrderingMethods: VenueOrderingMethod[],
  orderMethod: OrderMethod,
  delivery?: Delivery
): VenueOrderingMethod {
  const orderMethodSettings = venueOrderingMethods.find(
    (availOrderMethod: VenueOrderingMethod) =>
      // find order method settings for pickup or delivery
      availOrderMethod.orderMethod === orderMethod
  )
  // when venue has no order method settings (i.e hours or waittime)
  if (orderMethodSettings == null) {
    return {
      orderMethod,
      weeklySchedule: [],
      notice: '',
      waitTime: 0,
      isSchedulingAvailable: false,
      isASAPAvailable: false,
      nextAvailable: null,
      orderThrottlingLimitReached: false,
    }
  }

  if (orderMethod === OrderMethod.delivery && delivery != null) {
    // NOTE: this is to get the updated wait time into the current way of
    //       reading wait time in OOF so it can be read everywhere its needed
    orderMethodSettings.waitTime = delivery.waitTime
  }

  return orderMethodSettings
}

// converts a given order method's schedule times into a list of options
export function buildSchedulingTimes(timeslots: ScheduleTimeslotWithReason[]): {
  available: OptionsType[]
  unavailable: Pick<ScheduleTimeslotWithReason, 'time' | 'unavailabilityReason'>[]
} {
  return {
    available: timeslots
      .filter((timeslot) => timeslot.available)
      .map((timeslot) => {
        const timezoneRelativeTime = DateTime.fromISO(timeslot.time, {
          zone: DateTime.local().zone,
        })
        return {
          label: timezoneRelativeTime.toFormat('t').replace(/\./g, '').toUpperCase() ?? '',
          value: timezoneRelativeTime.toFormat('TT') ?? '',
        }
      }),
    unavailable: timeslots
      .filter((timeslot) => !timeslot.available)
      .map((timeslot) => {
        const timezoneRelativeTime = DateTime.fromISO(timeslot.time, {
          zone: DateTime.local().zone,
        })
        return {
          time: timezoneRelativeTime.toFormat('TT') ?? '',
          unavailabilityReason: timeslot.unavailabilityReason,
        }
      }),
  }
}

// Converts the given time to a relative readable string given the venue's availability
// e.g. if 8:50pm & nextIntervalTime = 15, then return 'in 10 minutes'
// e.g. if 9:00pm & nextIntervalTime = 30, then return 'in 30 minutes'
export const getRelativeTimeToNextAvailableInterval = (
  date: DateTime,
  nextIntervalTime: number,
  weeklySchedule: VenueOrderingMethod['weeklySchedule'],
  locale?: string
): string | null => {
  const schedulesForDay = weeklySchedule.filter((schedule) =>
    schedule.weekDays.some((weekDay) => date.weekdayShort?.toUpperCase() === weekDay)
  )

  // Iterate through each schedule to ensure that the timeslots contain the next interval time.
  // If it does, then return the relative time, otherwise return null, meaning that there is no next available time
  const timeOfNextInterval = date.plus({
    minute: nextIntervalTime - (date.minute % nextIntervalTime),
  })
  for (const schedule of schedulesForDay) {
    for (const timeslot of schedule.timeslots) {
      const interval = Interval.fromDateTimes(
        DateTime.fromFormat(timeslot.startTime, 'TT'),
        DateTime.fromFormat(timeslot.endTime, 'TT')
      )
      if (interval.contains(timeOfNextInterval)) {
        return timeOfNextInterval.toRelative({ locale })
      }
    }
  }

  return null
}

export const formatASAPOTLimitReachedMessage = (
  t: TFunction,
  isSchedulingAvailable: boolean,
  isDelivery: boolean,
  nextAvailableRelativeTimeASAP: string | null
): string =>
  t(
    `order_throttling.${
      isDelivery ? 'delivery' : 'pickup'
    }_asap_limit_reached_with_schedule_disabled${
      !isSchedulingAvailable && nextAvailableRelativeTimeASAP ? '_when' : ''
    }`,
    { when: nextAvailableRelativeTimeASAP }
  )

export function calcTipAmount(total: string, tipPercent: string): string {
  // 15% / 100 = 0.15
  // This number conversion prevents Big to break with empty strings
  const tipRate = Big(Number(tipPercent)).div(100)
  // $10 * 0.15 = $1.5
  const result = tipRate.times(total).toFixed(2)
  return result
}

// helper needed to filter the available order methods
// that can be selected in the header on the menu and venue pages
export function buildHeaderOrderMethods(
  venueOrderingMethods: VenueOrderingMethod[],
  orderMethod: OrderMethod,
  t: TFunction
): OptionsType[] {
  const methods = venueOrderingMethods
    .filter(
      ({
        isASAPAvailable: isASAPAvail,
        isSchedulingAvailable: isSchedulingAvail,
        orderMethod: method,
      }): boolean =>
        isASAPAvail ||
        isSchedulingAvail ||
        // we need this for when a user lands on /ordermethod
        // and it's disabled to show it as an option
        // in the header dropdown
        orderMethod === method
    )
    .map(({ orderMethod: method }): OptionsType => {
      const orderingMethod = method.toLowerCase()
      return {
        label: t(`order_method_dropdown.${orderingMethod}`),
        value: orderingMethod,
      }
    })

  const hasMethod = methods.some(
    (option: OptionsType) => option.value === orderMethod.toLowerCase()
  )

  if (!hasMethod) {
    // when a venue has completely disabled the order method
    const method = orderMethod.toLowerCase()
    methods.push({
      label: t(`order_method_dropdown.${method}`),
      value: method,
    })
  }

  return methods
}

export function getMenuPageTimeDisplay(
  menuPage: OnlineOperationalMenuPage,
  locale: string
): string | null {
  return menuPage.schedule.type === 'ALWAYS'
    ? null
    : `${formatTimeWithTimezone(menuPage.schedule.times.startTime, {
        lng: locale,
        setZone: false,
      })}
  –
  ${formatTimeWithTimezone(menuPage.schedule.times.endTime, {
    lng: locale,
    setZone: false,
  })}`
}

export const contrastingTextColor = (theme: Theme, color: string): string => {
  const { WHITE, TEXT } = theme.palette
  const hasContrast = meetsContrastGuidelines(color, TEXT)
  if (hasContrast.AA) {
    return TEXT
  }
  return WHITE
}

export const getCurrencyByCountry = (country: string): string => {
  switch (country) {
    case 'CA':
      return 'CAD'
    case 'US':
      return 'USD'
    default:
      // OO only supports CA/US
      return 'N/A'
  }
}

export const constructGTMSession = (
  venue: Venue,
  operationalMenu: OperationalMenu,
  profile: ConsumerProfile | null,
  guest: GuestInformation | null,
  cart: CartResponse,
  paymentMethod?: PaymentMethod
): GTMSession => {
  const menuItems = Object.values(operationalMenu.menuItems)
  const menuItemsWithImagesPct =
    menuItems.length > 0
      ? menuItems.filter((menuItem) => Boolean(menuItem.image)).length / menuItems.length
      : 0
  const deliveryFee = cart.bill.serviceCharges.find((sc) => sc.type === 'DELIVERY_FEE')

  return {
    venueXRefID: venue.venueXRefID,
    isTest: venue.venueIsTest,
    guestCheckoutAvailable: venue.guestCheckoutEnabled,
    scheduledOrderingAvailable: venue.venueOrderingMethods[0].isSchedulingAvailable,
    currency: venue.venueCurrency ?? getCurrencyByCountry(venue.venueCountry ?? ''),
    hasMenuItemImages: Boolean(menuItemsWithImagesPct),
    menuItemImagesPct: Math.round(menuItemsWithImagesPct * 100) / 100,
    consumerXRefID: profile?.consumerXRefID ?? null,
    isGuestCheckout: Boolean(guest),
    isScheduledOrder: Boolean(cart.scheduledFor),
    orderThrottled:
      venue.venueOrderingMethods.find(
        (orderingMethods) => orderingMethods.orderMethod === cart.orderMethod
      )?.orderThrottlingLimitReached ?? false,
    fulfillmentType:
      cart.orderMethod === OrderMethod.delivery && cart.delivery?.fleet === DeliveryFleet.DOORDASH
        ? 'DOORDASH_DRIVE'
        : cart.orderMethod ?? null,
    deliveryFeeTotal:
      cart.orderMethod === OrderMethod.delivery ? Number(deliveryFee?.totalAmount ?? 0) : 0,
    orderSubtotal: Number(cart.bill.subtotal),
    orderTotal: Number(cart.bill.grandTotal),
    orderItemsTotal: cart.items.reduce(
      (numberOfItems, item) => numberOfItems + Number(item.quantity),
      0
    ),
    discountTotal: Number(cart.bill.discountAmount ?? 0),
    paymentType: paymentMethod ?? null,
  }
}

export interface MapDiscountDetail {
  id: string
  label: string
  discountType: 'ITEM' | 'ORDER' | 'BOGO'
  applyType: 'AUTOMATIC' | 'MANUAL' | 'PROMO'
  total: string
}
